/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import { HOME_SCREEN, OFFER_ADDRESS_EDIT_SCREEN, OFFER_ADDRESS_VERIFY_SCREEN, OFFER_HOME_SCREEN, OFFER_STEP_PROPERTY_DETAILS, OFFER_STEP_SECOND_SCREEN, OFFER_THANKS_SCREEN, PROCESS_SCREEN, STEP_SECOND_SCREEN, THANKS_SCREEN } from "../constant"
function SEO({ description, lang, meta, metaImage = {}, title, structuredData, pathname = `` }) {
  const location = useLocation()
  const { site, noindex } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        noindex: allStrapiIntroSection {
          nodes {
            noIndexEnabled
          }
        }
      }
    `
  )

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'pageview',
      dtL_pageview: {
        branch: process.env.GATSBY_BRANCH
      }
    })
  }, [])

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = title || site.siteMetadata?.title
  const image = metaImage && metaImage.src
    ? `${site.siteMetadata.siteUrl}${metaImage.src}`
    : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const index = noindex.nodes[0].noIndexEnabled
  let pathArray = [HOME_SCREEN, PROCESS_SCREEN]
  let newFormPath = [OFFER_HOME_SCREEN, OFFER_STEP_SECOND_SCREEN, OFFER_STEP_PROPERTY_DETAILS, OFFER_THANKS_SCREEN, OFFER_ADDRESS_EDIT_SCREEN, OFFER_ADDRESS_VERIFY_SCREEN, THANKS_SCREEN, STEP_SECOND_SCREEN]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle}` : null}
      link={
        canonical
          ? [
            {
              rel: "canonical",
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        }, ...[index || newFormPath.includes(pathname) ? {
          name: `robots`,
          content: "noindex",
        } : {}]
      ]
        .concat(
          metaImage
            ? [
              {
                property: "og:image",
                content: image,
              }, {
                property: "og:image:height",
                content: metaImage.height,
              }, ...[pathArray.includes(pathname) ? {} : {
                property: "og:image:width",
                content: metaImage.width,
              }],
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        ).concat(meta)}
    >      
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO