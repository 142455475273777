export const GOOGLE_PLACE_API = "AIzaSyBk3yYpBKa9pDnmJDlNA1whOyPNxA6Mo3c"

export const HOME_SCREEN = "/"
export const STEP_SECOND_SCREEN = "/step-2/"
export const THANKS_SCREEN = "/thank-you/"
export const BLOG_SCREEN = "/wp/blog/"
export const PROCESS_SCREEN = "/our-process/"
export const PRIVACY_POLICY = "/privacy-policy/"
export const OFFER_HOME_SCREEN = "/get-offer/"
export const OFFER_STEP_SECOND_SCREEN = "/get-offer/step-2/"
export const OFFER_STEP_PROPERTY_DETAILS = "/get-offer/property-details/"
export const OFFER_THANKS_SCREEN = "/get-offer/thank-you/"
export const OFFER_ADDRESS_EDIT_SCREEN="/get-offer/address/edit/"
export const OFFER_ADDRESS_VERIFY_SCREEN = "/get-offer/address/verify/"
export const FAQ_SCREEN = "/faq/"
export const TERMS_AND_CONDITIONS = "/terms-and-conditions/"
export const ABOUT_US = "/about-us/"

export const yesNoOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
]

export const conditionJson = [
  {
    label: "Perfect",
    value: "Perfect",
  },
  {
    label: "Just OK",
    value: "Just OK",
  },
  {
    label: "Fair",
    value: "Fair",
  },
  {
    label: "Poor",
    value: "Poor",
  },
]

export const wantingJson = [
  {
    label: "Right Away",
    value: "Right Away",
  },
  {
    label: "1-3 Months",
    value: "1-3 Months",
  },
  {
    label: "3-6 Months",
    value: "3-6 Months",
  },
  {
    label: "6-12 Months",
    value: "6-12 Months",
  },
]

export const reasonJson = [
  {
    label: "Financial Hardship",
    value: "Financial Hardship",
  },
  {
    label: "Relocating",
    value: "Relocating",
  },
  {
    label: "Inherited",
    value: "Inherited",
  },
  {
    label: "Divorce",
    value: "Divorce",
  },
  {
    label: "Tired Landlord",
    value: "Tired Landlord",
  },
  {
    label: "Downsizing",
    value: "Downsizing",
  },
  {
    label: "Repairs/Damage",
    value: "Repairs/Damage",
  },
  {
    label: "Not Selling - Curious Of Home Value",
    value: "Not Selling - Curious Of Home Value",
  },
  {
    label: "Something Else",
    value: "Something Else",
  },
]

export const timeJson = [
  {
    label: "6 AM - 9 AM",
    value: "6 AM - 9 AM",
  },
  {
    label: "9 AM - 12 PM",
    value: "9 AM - 12 PM",
  },
  {
    label: "12 PM - 5 PM",
    value: "12 PM - 5 PM",
  },
  {
    label: "5 PM - 9 PM",
    value: "5 PM - 9 PM",
  },
]

export const propertyType = [
  {
    label: "Single Family",
    value: "Single Family",
  },
  {
    label: "Multi Family",
    value: "Multi Family",
  },
  {
    label: "Condo/Townhome",
    value: "Condo/Townhome",
  },
  {
    label: "Mobile/Manufactured",
    value: "Mobile/Manufactured",
  },
  {
    label: "Raw Land",
    value: "Raw Land",
  },
]

export const hereAboutType = [
  {
    label: "Google Search",
    value: "Google Search",
  },
  {
    label: "Bing Search",
    value: "Bing Search",
  },
  {
    label: "Facebook Ad",
    value: "Facebook Ad",
  },
  {
    label: "YouTube",
    value: "YouTube",
  },
  {
    label: "TV Commercial",
    value: "TV Commercial",
  },
  {
    label: "TV Show (Flip Or Flop)",
    value: "TV Show (Flip Or Flop)",
  },
  {
    label: "Tiktok",
    value: "Tiktok",
  },
  {
    label: "Snapchat",
    value: "Snapchat",
  },
  {
    label: "Radio",
    value: "Radio",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "Radio",
    value: "Radio",
  },
  {
    label: "Instagram",
    value: "Instagram",
  }
]

export const initialFormValues = {
  address: null,
  lead: null,
  id: null,
  street_address: null,
  city: null,
  google_county: null,
  state: null,
  zip: null,
  unit: null,
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  latitude: null,
  longitude: null,
  bedrooms: null,
  bathrooms: null,
  half_bathrooms: null,
  year_built: null,
  countertops: null,
  appliances: null,
  kitchen: null,
  bathrooms_condition: null,
  yard: null,
  exterior: null,
  priority: null,
  timeframe: null,
  message: null,
  zestimate: null,
  price_offered_from: null,
  price_offered_to: null,
  currently_listed: null,
  require_repairs: null,
  current_condition: null,
  urgency: null,
  asking_price: null,
  feel: null,
  source: null,
  gclid: null,
  msclkid: null,
  images: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_network: null,
  utm_keyword: null,
  utm_landing: null,
  utm_device: null,
  utm_creative: null,
  utm_location: null,
  utm_content: null,
  utm_term: null,
  reason_to_sell: null,
  time_to_call: null,
  property_type: null,
  here_about_type: null,
}

export const amountJson = [
  { value: "50,000 - 100,000", label: "50,000 - 100,000" },
  { value: "100,000 - 150,000", label: "100,000 - 150,000" },
  { value: "150,000 - 200,000", label: "150,000 - 200,000" },
  { value: "200,000 - 250,000", label: "200,000 - 250,000" },
  { value: "250,000 - 300,000", label: "250,000 - 300,000" },
  { value: "300,000 - 350,000", label: "300,000 - 350,000" },
  { value: "350,000 - 400,000", label: "350,000 - 400,000" },
  { value: "400,000 - 450,000", label: "400,000 - 450,000" },
  { value: "450,000 - 500,000", label: "450,000 - 500,000" },
  { value: "500,000 - 550,000", label: "500,000 - 550,000" },
  { value: "550,000 - 600,000", label: "550,000 - 600,000" },
  { value: "600,000 - 650,000", label: "600,000 - 650,000" },
  { value: "650,000 - 700,000", label: "650,000 - 700,000" },
  { value: "700,000 - 750,000", label: "700,000 - 750,000" },
  { value: "750,000 - 800,000", label: "750,000 - 800,000" },
  { value: "800,000 - 850,000", label: "800,000 - 850,000" },
  { value: "850,000 - 900,000", label: "850,000 - 900,000" },
  { value: "900,000 - 950,000", label: "900,000 - 950,000" },
  { value: "950,000 - 1,000,000", label: "950,000 - 1,000,000" },
  { value: "1,000,000 - 1,100,000", label: "1,000,000 - 1,100,000" },
  { value: "1,100,000 - 1,150,000", label: "1,100,000 - 1,150,000" },
  { value: "1,150,000 - 1,250,000", label: "1,150,000 - 1,250,000" },
  { value: "1,250,000 - 1,300,000", label: "1,250,000 - 1,300,000" },
  { value: "1,300,000 - 1,350,000", label: "1,300,000 - 1,350,000" },
  { value: "1,350,000 - 1,400,000", label: "1,350,000 - 1,400,000" },
  { value: "1,400,000 - 1,450,000", label: "1,400,000 - 1,450,000" },
  { value: "1,450,000 - 1,500,000", label: "1,450,000 - 1,500,000" },
  { value: "1,500,000+", label: "1,500,000+" },
]

export const iframes = [
  {
    url: "https://www.youtube.com/embed/eLBToOHBpO4",
    id: "eLBToOHBpO4",
  },
  {
    url: "https://www.youtube.com/embed/vjbp7kMFOT8",
    id: "vjbp7kMFOT8",
  },
  {
    url: "https://www.youtube.com/embed/Ytr9jLifs4I",
    id: "Ytr9jLifs4I",
  },
]

export const ANIMATION_DURATION = 400